<template>
  <HeaderComponent />
  <DrawerComponent />

  <div class="page">
    <div class="px-4">
      <form @submit.prevent="submitForm">
        <h1 class="text-2xl py-2 font-bold text-center">{{ user.nama }}</h1>
        <h1 class="text-2xl py-2 font-bold text-center">{{ user.email }}</h1>
        <h1 class="text-2xl py-2 font-bold">Change Password</h1>
        <div class="mb-4">
          <label for="new_password" class="block text-gray-700"
            >New Password</label
          >
          <input
            type="password"
            id="new_password"
            v-model="new_password"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            step="100"
          />
        </div>
        <div class="mb-4">
          <label for="confirm_password" class="block text-gray-700"
            >Confirm Password</label
          >
          <input
            type="password"
            id="confirm_password"
            v-model="confirm_password"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            step="100"
          />
        </div>
        <div class="flex justify-end py-8">
          <button
            type="submit"
            class="bg-green-600 text-white font-bold px-4 py-2 rounded shadow-md"
          >
            Change Password
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import AuthService from "@/AuthService";
import { ref, onMounted } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import DrawerComponent from "@/components/DrawerComponent.vue";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

const $toast = useToast();
let new_password = ref("");
let confirm_password = ref("");
let user = ref({});
onMounted(async () => {
  try {
    const response = await AuthService.userDetail();
    user.value = response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

const submitForm = () => {
  if (new_password.value == confirm_password.value) {
    const formData = {
      password: new_password.value,
    };
    editPassword(formData);
  } else {
    $toast.error("Konfirmasi Password Salah", { position: "top" });
  }
};

const editPassword = async (formData) => {
  try {
    const response = await AuthService.editPassword(formData);
    const { result, message } = response.data;
    if (result === "success") {
      $toast.success(message, { position: "top" });
    } else {
      $toast.error(message, { position: "top" });
    }
  } catch (error) {
    console.error("Error edit Password:", error);
  }
};
</script>
